html {
  padding: 0;
  box-sizing: border-box;

  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scroll-lock {
  overflow: hidden;
}

* {
  box-sizing: inherit;
}
#root {
  min-height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
p {
  margin: 0;
  padding: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.MuiInputBase-readOnly:hover fieldset.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
}
